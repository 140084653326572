.loader{
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
    top: 50%;
}
.scanner span {
    color: transparent;
    font-size: 1.4rem;
    position: relative;
    overflow: hidden;
  }
  
  .scanner span::before {
    content: "redhood.ai";
    position: absolute;
    height: 100%;
    border-right: 4px solid rgb(174 0 0);
    overflow: hidden;
    color: rgb(174 0 0);
    left: 10%;
    animation: loadAnimation 1.8s linear infinite;
  }
  
  @keyframes loadAnimation {
    0%, 10%, 100% {
      width: 0;
    }
  
    10%,20%,30%,40%,50%,60%,70%,80%,90%,100% {
      border-right-color: transparent;
    }
  
    11%,21%,31%,41%,51%,61%,71%,81%,91% {
      border-right-color: black;
    }
  
    60%, 80% {
      width: 100%;
    }
  }